import { userAxios } from '../../index'

const diagnosticImageList = async perPage => {
  try {
    return await userAxios.get(`insurance/sonograms/all/${perPage}`).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const diagnosticImageCreate = async data => {
  try {
    return await userAxios.post('insurance/sonograms', data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const diagnosticImageUpdate = async (id, data) => {
  try {
    return await userAxios.put(`insurance/sonograms/${id}`, data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const diagnosticImageDelete = async id => {
  try {
    return await userAxios.delete(`insurance/sonograms/${id}`).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const diagnosticImagePagination = async (perPage, page) => {
  try {
    return await userAxios
      .get(`insurance/sonograms/all/${perPage}?page=${page}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const diagnosticImageFilter = async (perPage, data) => {
  try {
    return await userAxios
      .post(`insurance/sonograms/search/${perPage}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const diagnosticImageFilterPagination = async (perPage, page, data) => {
  try {
    return await userAxios
      .post(`insurance/sonograms/search/${perPage}?page=${page}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

export default {
  diagnosticImageList,
  diagnosticImageCreate,
  diagnosticImageUpdate,
  diagnosticImageDelete,
  diagnosticImagePagination,
  diagnosticImageFilter,
  diagnosticImageFilterPagination,
}
